import React from 'react';

import SelectorWrapper from './common/SelectorWrapper';
import TypeButton from './common/TypeButton';
import ClaimStepContainer from './common/ClaimStepContainer';
import Column from '../../common/layout/Column';
import Row from '../../common/Row';
import { featureToggled } from '../../../utils/featureToggle';
import { useClaimsShopSettings } from '../ClaimsShopSettingsContext';

import DamageIcon from '../../../icons/damage.svg';
import InvestigationIcon from '../../../icons/investigation.svg';
import MissingItemIcon from '../../../icons/missing-item.svg';
import CashOnDeliveryIcon from '../../../icons/cash-on-delivery.svg';

import './ChooseClaimTypeStep.scss';

function ChooseClaimTypeStep(props) {
  const { onClickDamage, onClickInvestigation, onClickMissingItem, onClickCashOnDelivery, claim, ...restProps } = props;

  const { isClaimMeCustomer } = useClaimsShopSettings();
  const isCashOnDeliveryFeatureEnabled = featureToggled('cash-on-delivery');

  const getSubmissionBlockProps = (type) => {
    if (!claim.submissionInfo) {
      // missing submissionInfo implies manual claim thus we block based on shop settings and not shipment info

      if (type === 'cash_on_delivery' && !isClaimMeCustomer) {
        return { disabled: true, warningMessage: getWarningMessageForBlock('not_claim_me_customer') };
      }

      return { disabled: false };
    }

    if (!claim.submissionInfo[type].block) {
      return { disabled: false };
    }

    return { disabled: true, warningMessage: getWarningMessageForBlock(claim.submissionInfo[type].block) };
  };

  return (
    <ClaimStepContainer {...restProps}>
      <SelectorWrapper>
        <Column spacing="medium">
          <Row spacing={24}>
            <TypeButton
              icon={DamageIcon}
              title="Damage"
              description="CLAIM_DAMAGE_CHOICE_DESCRIPTION"
              onClick={onClickDamage}
              {...getSubmissionBlockProps('whole_damage')}
            />
            <TypeButton
              icon={InvestigationIcon}
              title="Investigation"
              description="CLAIM_INVESTIGATION_CHOICE_DESCRIPTION"
              onClick={onClickInvestigation}
              {...getSubmissionBlockProps('investigation')}
            />
          </Row>
          {isCashOnDeliveryFeatureEnabled && (
            <Row spacing={24}>
              <TypeButton
                icon={MissingItemIcon}
                title="Missing item"
                description="CLAIM_MISSING_ITEM_CHOICE_DESCRIPTION"
                onClick={onClickMissingItem}
                {...getSubmissionBlockProps('missing_item')}
              />
              <TypeButton
                icon={CashOnDeliveryIcon}
                title="Cash on delivery"
                description="CLAIM_CASH_ON_DELIVERY_CHOICE_DESCRIPTION"
                onClick={onClickCashOnDelivery}
                {...getSubmissionBlockProps('cash_on_delivery')}
              />
            </Row>
          )}
          {!isCashOnDeliveryFeatureEnabled && (
            <TypeButton
              icon={MissingItemIcon}
              title="Missing item"
              description="CLAIM_MISSING_ITEM_CHOICE_DESCRIPTION"
              onClick={onClickMissingItem}
              {...getSubmissionBlockProps('missing_item')}
            />
          )}
        </Column>
      </SelectorWrapper>
    </ClaimStepContainer>
  );
}

function getWarningMessageForBlock(block) {
  switch (block) {
    case 'not_claim_me_customer':
      return 'CASH_ON_DELIVERY_WARNING_NOT_CLAIM_ME_CUSTOMER';
    case 'not_outbound_shipment':
      return 'CASH_ON_DELIVERY_WARNING_NOT_OUTBOUND_SHIPMENT';
    case 'not_allowed_7s_shipment':
      return 'CASH_ON_DELIVERY_WARNING_7S_SHIPMENT';
    default:
      return 'Unknown reason';
  }
}
export default React.memo(ChooseClaimTypeStep);
