import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../common2/Input';
import Locale from '../../../Translate/Locale';
import Link from '../../../common/Link/Link';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import { trackingInfoValidator } from '../claimValidators';
import useStepValidation from './useStepValidation';

import './SearchClaimInfoStep.scss';

function SearchClaimInfoStep(props) {
  const {
    as: Container,
    onLoadShipmentByTrackingId,
    onResetShipmentSearch,
    onNext,
    onBack,
    onChange,
    onResetClaim,
    isDisabled,
    isSubmitting,
    nonClaimableMessage,
    claim: { trackingCode },
    ...restProps
  } = props;

  const [isClaimSubmissionBlocked, setIsClaimSubmissionBlocked] = useState(true);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);

  const onInputChange = useCallback(
    (value, name) => {
      onChange({ [name]: value });
      setIsNextBtnDisabled(false);
    },
    [onChange]
  );

  const onBackButtonClick = useCallback(() => {
    onResetShipmentSearch();
    onBack();
  }, [onBack, onResetShipmentSearch]);

  const loadClaimInfoFromShipment = useCallback(async () => {
    const { isClaimSubmissionBlocked } = await onLoadShipmentByTrackingId(trackingCode);
    if (isClaimSubmissionBlocked) {
      setIsNextBtnDisabled(true);
    }
    setIsClaimSubmissionBlocked(isClaimSubmissionBlocked);
  }, [trackingCode, onNext, onLoadShipmentByTrackingId]);

  // TODO: we need to rewrite shipment fetching logic to not rely in delayed state updates
  useEffect(() => {
    if (!isClaimSubmissionBlocked) {
      onNext();
    }
  }, [isClaimSubmissionBlocked]);

  useEffect(() => {
    onResetClaim();
  }, []);

  const [error, onClickNext] = useStepValidation({ trackingCode }, loadClaimInfoFromShipment, trackingInfoValidator);

  return (
    <Container
      {...restProps}
      onNext={onClickNext}
      onBack={onBackButtonClick}
      isDisabled={isDisabled || isNextBtnDisabled}
      isSubmitting={isSubmitting}
      contentHeading="CLAIM_CENTER_TRACKING_ID_STEP_HEADING"
    >
      {nonClaimableMessage && (
        <ClaimStepClosableMessage intent="danger">
          <Locale>{nonClaimableMessage}</Locale>
        </ClaimStepClosableMessage>
      )}
      <Input
        name="trackingCode"
        label="SHIPPING_INFO_TRACKING_ID"
        placeholder="Add tracking ID"
        isRequired
        value={trackingCode}
        error={error.trackingCode}
        onChange={onInputChange}
        topRightElement={
          <Link to="/parcel-finder">
            <div styleName="link">
              <Locale>Don’t have the tracking ID?</Locale>
            </div>
          </Link>
        }
      />
    </Container>
  );
}

SearchClaimInfoStep.propTypes = {
  as: PropTypes.elementType.isRequired,
  claim: PropTypes.object.isRequired,
  isSubmitButton: PropTypes.bool,
  onLoadShipmentByTrackingId: PropTypes.func,
};

export default React.memo(SearchClaimInfoStep);
