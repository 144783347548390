/* eslint-disable camelcase */
import { useState, useCallback } from 'react';

import useNotifications from '../../../hooks/useNotifications';
import { createClaimFromShipment } from '../../parcel-finder/claims/claimsHelpers';
import container from '../../../container';

const nonClaimableMessages = {
  claim_exists_for_shipment_id: 'CLAIM_ALREADY_EXISTS_FOR_SHIPMENT',
  claims_not_enabled: 'CLAIM_NOT_ENABLED',
  not_allowed_7s_shipment: 'CLAIMS_CENTER_CASH_ON_DELIVERY_IS_7S_SHIPMENT',
  not_outbound_shipment: 'CLAIMS_CENTER_CASH_ON_DELIVERY_IS_NOT_OUTBOUND_SHIPMENT',
};

function useSearchShipment({ claim, changeClaim = () => {}, resetClaim = () => {} }) {
  const { showError } = useNotifications();
  const [isSearching, setIsSearching] = useState(false);
  const [nonClaimableMessage, setNonClaimableMessage] = useState();

  const resetShipmentSearch = useCallback(() => {
    resetClaim();
    setNonClaimableMessage();
  }, [resetClaim, setNonClaimableMessage]);

  const loadShipmentByTrackingId = useCallback(
    async (trackingCode) => {
      setIsSearching(true);
      setNonClaimableMessage();

      try {
        const response = await container.touchpointsApi.getShipmentWithClaimableInfoByTrackingCode(trackingCode);
        if (response.length > 0) {
          const shipment = response[0];
          const nonClaimableCode = shipment.nonClaimableReasonCode || shipment.claimSubmissionInfo[claim.type].block;

          if (!nonClaimableCode) {
            const claimFromShipment = createClaimFromShipment(response[0]);
            changeClaim(claimFromShipment);
          }

          setIsSearching(false);
          setNonClaimableMessage(nonClaimableCode ? getNonClaimableMessage(nonClaimableCode) : null);

          return { isClaimSubmissionBlocked: !!nonClaimableCode };
        }

        setIsSearching(false);

        return { isClaimSubmissionBlocked: false };
      } catch (err) {
        setIsSearching(false);
        showError();

        return { isClaimSubmissionBlocked: true };
      }
    },
    [changeClaim, resetClaim, setNonClaimableMessage, showError, claim.type, claim]
  );

  return {
    isSearching,
    nonClaimableMessage,
    resetShipmentSearch,
    loadShipmentByTrackingId,
  };
}

function getNonClaimableMessage(code) {
  return nonClaimableMessages[code] || 'Unknown reason';
}

export default useSearchShipment;
