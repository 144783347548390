import React from 'react';
import PageContainer from '../pagecontainer/PageContainer';
import { ClaimsShopSettingsContextProvider } from './ClaimsShopSettingsContext';
import PageLoader from '../PageLoader';
import { useSelectedShopId } from '../../hooks/useSelectedShop';
import { useQuery } from '@tanstack/react-query';
import container from '../../container';

export const menuItems = [
  {
    id: '/parcel-finder',
    link: '/parcel-finder',
    title: 'Parcel finder',
  },
  {
    id: '/parcel-finder/claims-center',
    link: '/parcel-finder/claims-center',
    matchPaths: ['/parcel-finder/claims-center*'],
    title: 'Claims center',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/parcel-finder/carrier-claim-payments',
    link: '/parcel-finder/carrier-claim-payments',
    title: 'Carrier claim payments',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/analytics/claims/claims-overview',
    link: '/analytics/claims/claims-overview',
    title: 'Claims overview',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/parcel-finder/start-guide',
    link: '/parcel-finder/start-guide',
    title: 'Start guide',
  },
];

/**
 * @param {ClaimsShopSettings} [settings]
 */
function getMenuItems(settings) {
  return settings?.isClaimMeCustomer
    ? menuItems
    : menuItems.filter((item) => item.id !== '/parcel-finder/carrier-claim-payments');
}

function ParcelFinderContainer(props) {
  const shopId = useSelectedShopId();

  /**
   * @type {import('@tanstack/react-query').UseQueryResult<ClaimsShopSettings>}
   */
  const { isLoading, data: settings } = useQuery({
    queryKey: ['claimsShopSettings', shopId],
    queryFn: () => container.touchpointsApi.getClaimsShopSettings(shopId),
    refetchOnMount: false,
  });

  if (isLoading) {
    return <PageLoader {...props} />;
  }

  return (
    <PageContainer {...props} menuTitle="Parcel Finder + Claims" menuItems={getMenuItems(settings)}>
      <ClaimsShopSettingsContextProvider value={settings}>{props.children}</ClaimsShopSettingsContextProvider>
    </PageContainer>
  );
}

export default ParcelFinderContainer;
